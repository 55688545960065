const tutorials = [
  '🎓 Get Started: Introduction to Robocolumns',
  '🏫 Onboarding Resource: Learn Robocolumns',
  '🎓 Get Started: Introduction to Filter Plates',
  '🎓 Get Started: Introduction to Robocolumns DOE',
  '🎓 Get Started: Design of Experiments on Synthace',
  '🎓 Get Started: Using DOE with dispensers',
  '🏫 Onboarding Resource: DOE Workshop - Creating DOE workflows and designs',
  '🏫 Onboarding Resource: Robocolumns DOE Workshop',
  '🎓 Get Started: Introduction to ELISA',
  '🎓 Get Started: Learn the Core Elements',
  '🧱 Concept: Defining multistep processes',
  '🔬 Examples: Exploring Basic Quantification Assays',
  '🔬 Examples: Sandwich ELISA',
  '🔦 Element Spotlight: Using Append',
  '🔦 Element Spotlight: Using Make Mixtures',
  '🔦 Element Spotlight: Using Mix Onto',
  '🔦 Element Spotlight: Using Synchronise',
  '🔦 Element Spotlight: Calculate Stock Concentrations',
  '📖 Tutorial: 1. Define Liquids and Plates',
  '📖 Tutorial: 2. Allocate liquids to plates and wells',
  '📖 Tutorial: 3. Dilute',
  '📖 Tutorial: 4. Normalize',
  '📖 Tutorial: 5. Make Mixtures',
  '📖 Tutorial: 6. Mix Onto',
  '📖 Tutorial: 7. Utility Elements',
  '📖 Tutorial: 8. Select',
  '📖 Tutorial: 9. Combining small workflow units',
  '📖 Tutorial: 10. Building basic assays',
  '📖 Tutorial: 11. Advanced Assays',
  '📖 Tutorial: 12. Build flexible, scalable workflows with the core elements',
];

export const tutorialsOrdering = Object.fromEntries(
  tutorials.map((name, i) => [name, i]),
);
