import React, { createContext, ReactNode, useContext } from 'react';

import { MixPreview } from 'common/types/mixPreview';
import { PlateType } from 'common/types/plateType';

type PrintoutData = {
  simulationName: string;
  startedAt: string;
  mixPreview: MixPreview | null;
  plateTypes: readonly PlateType[];
};

const PrintoutDataContext = createContext<PrintoutData>({
  mixPreview: null,
  plateTypes: [],
  simulationName: '',
  startedAt: '',
});

type Props = {
  children: ReactNode | ReactNode[];
  data: PrintoutData;
};

export default function PrintoutDataProvider({ children, data }: Props) {
  return (
    <PrintoutDataContext.Provider value={data}>{children}</PrintoutDataContext.Provider>
  );
}

export function useStagePrintout() {
  return useContext(PrintoutDataContext);
}
