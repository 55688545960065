import React, { useMemo } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { formatDuration } from 'common/lib/format';
import { stringToMarkdown } from 'common/lib/markdown';
import { MixPreviewStages } from 'common/types/mixPreview';
import { MarkdownPreview } from 'common/ui/components/MarkdownPreview';
import { useManualInterventions } from 'common/ui/components/simulation-details/mix/ManualInterventions/ManualInterventionContext';
import SingleKeyPoint from 'common/ui/components/simulation-details/StepSlider/components/SingleKeyPoint';
import {
  ManualInterventionDarkIcon,
  ManualInterventionIcon,
  ManualInterventionLightIcon,
  PromptInfoIcon,
} from 'common/ui/components/simulation-details/StepSlider/components/styles';
import {
  areAdjacent,
  getKeyPointPosition,
  getManualActionSequences,
  getPrevKeyPoint,
  shouldMergeManualStep,
} from 'common/ui/components/simulation-details/StepSlider/helpers';
import { KeyPoint } from 'common/ui/components/simulation-details/StepSlider/types';

type Props = {
  currentKeyPoint: KeyPoint | undefined;
  keyPoints: readonly KeyPoint[][];
  currentStage: number;
  stages: MixPreviewStages;
  sliderWidth: number;
};

function KeyPoints({
  currentKeyPoint,
  keyPoints,
  currentStage,
  stages,
  sliderWidth,
}: Props) {
  const manualInterventions = useManualInterventions();
  const manualActionSequences = useMemo(
    () => getManualActionSequences(keyPoints),
    [keyPoints],
  );

  return (
    <>
      {keyPoints.flatMap((stageKeyPoints, stageIndex) => {
        const stageManualSequences = manualActionSequences[stageIndex];
        const shouldMerge = shouldMergeManualStep(
          stageManualSequences,
          stageIndex,
          stages,
          sliderWidth,
        );
        const getPosition = getKeyPointPosition(stageIndex, sliderWidth, stages);

        return stageKeyPoints.map((keyPoint, keyPointIdx) => {
          const id = `stage-${stageIndex}-step-${keyPoint.step}`;
          const isActive =
            currentStage === stageIndex && currentKeyPoint?.step === keyPoint.step;

          const forcePopperOpen = shouldForcePopperOpen(
            keyPoint,
            keyPointIdx,
            manualInterventions.showAll,
            stageKeyPoints,
          );

          const sequence = stageManualSequences.get(keyPointIdx);
          const iconContent = (
            <Stack px={2}>
              {sequence && sequence.size > 1 ? (
                <MultipleManualActions />
              ) : (
                <ManualInterventionDarkIcon />
              )}
            </Stack>
          );
          const markdownContent = (
            <Stack maxWidth={450}>
              <Stack direction="row" alignItems="baseline" gap={3}>
                {keyPoint.isManualIntervention ? (
                  <>
                    <ManualInterventionIcon />
                    <Typography
                      component="span"
                      variant="body1"
                      fontWeight={700}
                      whiteSpace="nowrap"
                    >
                      Manual intervention -
                    </Typography>
                  </>
                ) : (
                  <PromptInfoIcon />
                )}
                {keyPoint.popper?.message && (
                  <Typography component="span" variant="body1" fontWeight={400}>
                    <MarkdownPreview
                      markdown={stringToMarkdown(keyPoint.popper.message)}
                    />
                  </Typography>
                )}
              </Stack>
              {keyPoint.popper?.duration && keyPoint.popper.duration > 0 && (
                <Typography variant="body1" fontWeight={700} alignSelf="flex-end" pb={2}>
                  {`Duration: ${formatDuration(keyPoint.popper.duration)}`}
                </Typography>
              )}
            </Stack>
          );

          return (
            <SingleKeyPoint
              id={id}
              key={id}
              keyPoint={keyPoint}
              position={getPosition(keyPoint.step)}
              shouldMerge={shouldMerge(keyPointIdx)}
              isActive={isActive}
              popper={{
                content: isActive
                  ? markdownContent
                  : forcePopperOpen
                  ? iconContent
                  : null,
                forceOpen: forcePopperOpen,
              }}
            />
          );
        });
      })}
    </>
  );
}

const MultipleManualActions = () => (
  <Stack position="relative" width={20} height={25}>
    <ManualInterventionDarkIcon sx={{ position: 'absolute', zIndex: 2, left: 0 }} />
    <ManualInterventionLightIcon sx={{ position: 'absolute', zIndex: 1, left: 4 }} />
  </Stack>
);

function shouldForcePopperOpen(
  keyPoint: KeyPoint,
  keyPointIdx: number,
  showAll: boolean,
  stageKeyPoints: KeyPoint[],
) {
  let forcePopperOpen = Boolean(keyPoint.isManualIntervention && showAll);
  if (forcePopperOpen) {
    const prevKeyPoint = getPrevKeyPoint(keyPointIdx, stageKeyPoints);
    if (prevKeyPoint?.isManualIntervention && areAdjacent(keyPoint, prevKeyPoint)) {
      forcePopperOpen = false;
    }
  }
  return forcePopperOpen;
}

export default React.memo(KeyPoints);
